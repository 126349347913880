import React, { Component } from "react";
import Layout from "templates/basic/";
import SEO from 'components/seo';
import { graphql } from 'gatsby';
import style from "templates/basic/locations.module.scss";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import TextInput from 'components/form/textinput/';
import Select from 'components/form/select/';
import ReCAPTCHA from 'react-google-recaptcha';
import CountryInput from 'components/form/country/';
import Background from 'components/background';
import Intro from 'components/intro';
import Link from 'components/link';

import zparse from 'helpers/parse';

const subjectOptions = [
	'Have a salesman call me',
	'Schedule a lunch and learn',
	'Employment',
	'I am a vendor',
	'Employee Login',
	'Human Resources Questions',
	'Other'
];

export default class ContactPage extends Component {

	constructor(props) {
		super(props);
		this.locations = [];
		this.page = {};
		if (props.data.allLocationsLocation && props.data.allLocationsLocation.edges.length) this.locations = props.data.allLocationsLocation.edges;
		if (this.locations.length) {
			this.locations = this.locations.map(location => {
				let parsed = location.node;
				return { node: parsed }
			})
		}
		if (props.data.allContentPage && props.data.allContentPage.edges.length) this.page = props.data.allContentPage.edges[0].node;
		this.grid = this.grid.bind(this);
		this.recaptchaComplete = this.recaptchaComplete.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.timerID = null;
		this.phoneConvert = this.phoneConvert.bind(this);

		this.contactSchema = Yup.object().shape({
			firstName: Yup.string()
				.required('Please enter your first name.'),
			lastName: Yup.string()
				.required('Please enter your last name.'),
			email: Yup.string()
				.email('Please enter a valid email address.')
				.required('Please enter your email address.'),
			subject: Yup.string()
				.required('Please select a subject for this message.'),
		});
		this.state = {
			recaptcha: '',
			recaptchaError: false,
			success: false,
		}
		this.initialValues = {
			firstName: '',
			lastName: '',
			company: '',
			email: '',
			country: '',
			phone: '',
			subject: '',
			otherSubject: '',
			env: 'CONTACT_FORM',
			mailto: 'email1',
		}
	}

	recaptchaComplete(value) {
		this.setState({
			recaptcha: value,
			recaptchaError: false,
		});
	}

	phoneConvert(input) {
		var inputlength = input.length;
		input = input.toLowerCase();
		var phonenumber = "";
		for (let i = 0; i < inputlength; i++) {
			var character = input.charAt(i);

			switch (character) {
				case '0': phonenumber += "0"; break;
				case '1': phonenumber += "1"; break;
				case '2': phonenumber += "2"; break;
				case '3': phonenumber += "3"; break;
				case '4': phonenumber += "4"; break;
				case '5': phonenumber += "5"; break;
				case '6': phonenumber += "6"; break;
				case '7': phonenumber += "7"; break;
				case '8': phonenumber += "8"; break;
				case '9': phonenumber += "9"; break;
				case '-': phonenumber += "-"; break;
				case 'a': case 'b': case 'c': phonenumber += "2"; break;
				case 'd': case 'e': case 'f': phonenumber += "3"; break;
				case 'g': case 'h': case 'i': phonenumber += "4"; break;
				case 'j': case 'k': case 'l': phonenumber += "5"; break;
				case 'm': case 'n': case 'o': phonenumber += "6"; break;
				case 'p': case 'q': case 'r': case 's': phonenumber += "7"; break;
				case 't': case 'u': case 'v': phonenumber += "8"; break;
				case 'w': case 'x': case 'y': case 'z': phonenumber += "9"; break;
				default:
			}
		}
		phonenumber = phonenumber.replace(/-/g, '');
		return phonenumber;
	}

	componentWillUnmount() {
		if (this.timerID !== null) clearTimeout(this.timerID);
		this.timerID = null;
	}

	submitForm(values, { setSubmitting, resetForm }) {
		if (subjectOptions.indexOf(values.subject) >= 1) values.mailto = `email${subjectOptions.indexOf(values.subject)}`
		if (!this.state.recaptcha) {
			setSubmitting(false);
			this.setState({
				recaptchaError: true,
			});
			return;
		}
		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(Object.assign({}, values, { recaptcha: this.state.recaptcha })),
		};
		fetch(`${process.env.GATSBY_ZMS_API_URL}contact/mail/`, options)
			.then(async function (response) {
				if (!response.ok) {
					const body = await response.json();
					throw new Error(body.errorMessage);
				}
				return response.json();
			})
			.then((json) => {
				this.setState({
					success: true,
				});
				if (this.timerID) clearTimeout(this.timerID);
				this.timerID = setTimeout(() => {
					this.timerID = null;
				}, 3000);

			})
			.catch(function (err) {
				alert(err);
			})
			.finally(function () {
				setSubmitting(false);
				document.getElementById('contactForm').reset();
				resetForm();
			})
			;
	}

	grid({ data }) {
		const locationsArr = data.map(({ node }, index) => {
			return (
				<div key={index} className="cell">
					<div className={style.locationInnerWrapper}>
						<h3 className={style.name}>{node.name}</h3>
						<p className={style.address}>{node.street} {node.suite ? `${node.suite}` : ''}<br />{node.city}, {node.state} {node.zip}{node.tollfree ? '\nToll Free: ' : ''}{node.tollfree ? <a href={`tel:+1${this.phoneConvert(node.tollfree)}`}>{node.tollfree}</a> : ''}{node.phone ? '\nPhone: ' : ''}{node.phone ? <a href={`tel:+1${this.phoneConvert(node.phone)}`}>{node.phone}</a> : ''}{node.fax ? '\nHR Fax: ' + node.fax : ''}</p>
					</div>
				</div>
			);
		});
		return (locationsArr);
	}
	render() {
		return (
			<>
				<Layout image={this.page.headerImage} preTitle={this.page.pageTitle} title={this.page.subTitle} link={this.page.link} linkText={this.page.linkText} enableDanosFoundation={this.page.danosFoundation} enableFooterTabs={this.page.danosFooterTab}>
					<Intro pullQuote={this.page.pullQuote} headline={this.page.headline} pageCopy={this.page.pageCopy} />
					<div className="grid-container">
						<div className="grid-x grid-margin-x">
							<div className="cell small-12">
								{this.page.pageCopy && <hr style={{ margin: "60px auto" }} />}
								<div className={style.innerContent}>{this.page ? zparse(this.page.content.main) : ''}</div>
							</div>
						</div>
					</div>
					{this.locations &&
						<div className={`grid-container ${style.locationCardWrapper}`}>
							<div className="grid-x grid-padding-x small-up-1 large-up-4">
								<this.grid data={this.locations} />
							</div>
						</div>
					}
					<section className={style.formSection}>
						<div className="grid-container">
							<div className="grid-x grid-margin-x">
								<div className="cell small-12 medium-12">
									<h3>Get In Touch</h3>
									<Formik
										initialValues={this.initialValues}
										validationSchema={this.contactSchema}
										onSubmit={this.submitForm}
									>
										{({ values, setFieldValue, isSubmitting }) => (
											<Form id="contactForm">
												<div className={`${style.formFields} grid-x grid-margin-x small-up-1 medium-up-2 ${this.state.success ? style.hide : 'showMe'}`}>
													<Field className={`${style.cell} cell`} placeholder="First Name" name="firstName" label="First Name *" component={TextInput} />
													<Field className={`${style.cell} cell`} placeholder="Last Name" name="lastName" label="Last Name *" component={TextInput} />
													<Field className={`${style.cell} cell`} placeholder="Company" name="company" label="Company" component={TextInput} />
													<Field className={`${style.cell} cell`} placeholder="Email Address" name="email" label="Email Address *" component={TextInput} />
													<Field className={`${style.cell} cell`} name="country" label="Country" component={CountryInput} />
													<Field className={`${style.cell} cell`} placeholder="Phone" name="phone" label="Phone Number" component={TextInput} />
													<Field className={`${style.cell} cell`} value={values.subject} name="subject" label="Subject" options={subjectOptions} placeholder="Select One" default="General" component={Select} />
													<Field className={[(values.subject === 'Other' ? '' : 'hide'), style.cell, 'cell'].join(' ')} placeholder="Subject" name="otherSubject" label="Other" component={TextInput} />
												</div>
												<div className="grid-x grid-margin-x">
													<div className={`${style.cell} cell text-right`} style={{ marginBottom: '1rem', position: 'relative' }}><div style={{ zIndex: 2, position: 'relative' }}><ReCAPTCHA sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY} onChange={this.recaptchaComplete} /></div><div className={[style.error, (this.state.recaptchaError ? style.active : '')].join(' ')}>You must complete the ReCAPTCHA</div></div>
												</div>
												<div className={`grid-x grid-margin-x ${this.state.success ? style.hide : 'showMe'}`}>
													<div className="cell text-left">
														<button className={`${style.submitButton} button`} type="submit" disabled={isSubmitting}>Submit</button>
													</div>
												</div>
												{<div className={[style.success, (this.state.success ? style.showSuccess : '')].join(' ')}>Thank you for contacting Danos. Someone will receive your message shortly.</div>}
											</Form>
										)}
									</Formik>
								</div>
							</div>
						</div>
					</section>
					<section id="media" className={`${style.mediaContactWrapper} grid-container`}>
						<div className="grid-x grid-margin-x small-margin-collapse posRel">
							<div className={[style.floater, "cell small-12 medium-5"].join(" ")}>
								<h3>Media Contact</h3>
								<p>Through our media contact, you’ll be able to find the most current information about our operations. We can also supply consumer and trade media with Danos photos, logos and other materials helpful to getting production asset management stories into the news stream.</p>
								<p className={style.mediaContact}>Samantha Magee, Media Relations Coordinator<br /><Link external={true} href="tel:+19852193433">985-219-3433</Link><br /><Link external={true} href="mailto:Samantha.Magee@danos.com">Samantha.Magee@danos.com</Link></p>
							</div>
							<div className={`${style.imageCell} cell small-12 medium-6 medium-offset-4`}>
								<Background className={style.background} image="images/mediaContact.png" />
							</div>
						</div>
					</section>
				</Layout>
				<SEO title="Contact Us" />
			</>
		)
	}
}

export const query = graphql`
	query LocationsQuery {
	  allLocationsLocation(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
			lang
	        name
			street
			city
			state
			zip
			tollfree
			phone
			fax
			internal {
			  content
			}
	      }
	    }
	  }
	  allContentPage(filter: {uri: {eq: "/contact-us/"}, lang: {eq: "en"}}) {
	    edges {
	      node {
	        id
	        uri
	        content {
	          main
	        }
			lang
	        pageTitle
			subTitle
			pullQuote
			pageCopy
			headline
			headerImage
			link
			linkText
			danosFoundation
			danosFooterTab
	      }
	    }
	  }
	}
`