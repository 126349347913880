import React from 'react'
import style from './blockquote.module.scss';

export default (props) => {
	let text = props.children.split(' ');
	const lastWord = text.pop();
	text = text.join(' ');
	return (
		<blockquote className={style.blockQuote}>{ text } <span className={ style.lastWord }>{ lastWord }</span></blockquote>
	)
}