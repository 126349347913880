import React from "react"
import parse, { domToReact } from 'html-react-parser';

import Link from 'components/link'

const parserOptions = {
	htmlparser2: {
		lowerCaseTags: false
	},
	replace: ({ name, attribs, children, type, data }) => {
		switch (name) {
			case "a":
				attribs.className = attribs.class;
				return <Link {...attribs}>{ domToReact(children) }</Link>
			case "p":
				attribs.className = attribs.class;
				return <p {...attribs}>{ domToReact(children) }</p>
			default:
		}
		if (!name && type === "text") return <p className="font-style-s5">{ data }</p>
	}
};

export default function zparse(str, options = parserOptions) {
	if (!str) return "";
	return parse(str, options);
}