import React, { Component } from "react"
import Blockquote from 'components/blockquote'
import style from './intro.module.scss'
import zparse from 'helpers/parse';

export default class Intro extends Component {
	render() {
		const headerOverride = this.props.headerTextCase !== undefined ? {textTransform: this.props.headerTextCase} : {textTransform: "uppercase"}
		return (
			<section className={[this.props.pullQuote ? '' : style.smallMarg, "intro"].join(' ')}>
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className={[this.props.pullQuote ? 'medium-6 ' : 'medium-12 ', "cell small-12"].join(' ')}>
							<h1 style={headerOverride}>{this.props.headline}</h1>
							{zparse(this.props.pageCopy)}
						</div>
						{this.props.pullQuote && <div className="cell small-12 medium-offset-1 medium-5">
							<Blockquote>{this.props.pullQuote}</Blockquote>
						</div>}
					</div>
				</div>
			</section>
		)
	}
}